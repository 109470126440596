import React, { useState } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const ToggleButton = ({
    toggled: initialToggled,
    onChange,
    onClick,
    ...props
}) => {
    const [toggled, setToggled] = useState(initialToggled || false);

    const handleToggleChange = () => {
        const newState = !toggled;
        setToggled(newState);
        if (onChange) {
            onChange(newState);
        }
    };

    return (
        <label>
            <Toggle
                checked={toggled}
                icons={false}
                onChange={handleToggleChange}
                onClick={onClick}
                {...props}
            />
        </label>
    );
};

export default ToggleButton;
